<template>
  <div id="tooltip" v-show="visible" :class="[{'visible': visible && text}, {'nowrap': !forcedWidth || typeof parseInt(forcedWidth) !== 'number'}]" :style="'top: ' + top + 'px; left: ' + left + 'px;' + forcedWidth">
    <div v-if="trustedText" class="text" v-html="text"></div>
    <div v-else class="text">{{text}}</div>
    <div v-if="subtext && subtext.length > 0" class="subtext">
      <div v-if="trustedSubtext" v-html="subtext"></div>
      <div v-else>{{subtext}}</div>
    </div>
    <div
      :class="['pointer', pointerLocation, pointerDirection]"
      :style="'top: ' + pointerTop + 'px; left: ' + pointerLeft + 'px;'"></div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: "tooltip",
  props: {
    //
  },
  data: function() {
    const vm = this;

    return {
      text: '',
      subtext: '',
      trustedText: false,
      trustedSubtext: false,
      visible: false,
      position: null,
      top: -10000,
      left: -10000,
      pointerTop: 0,
      pointerLeft: 0,
      pointerDirection: '',
      pointerLocation: null,
      forcedWidth: '',
      observer: null
    }
  },
  methods: {
    calculateSafeLocation: function (t) {
      const vm = this;

      const offset = t.offset();

      const tooltip = $('#tooltip');
      const tooltipHeight = tooltip.height();
      const tooltipWidth = tooltip.width();

      const pointer = $('#tooltip .pointer')[0];
      const pointerSize = Math.min(pointer.offsetHeight, pointer.offsetWidth);

      let position = {
        vertical: null,
        horizontal: null
      };

      // determine where to position vertically
      if (window.innerHeight - (offset.top + t.height() + pointerSize + tooltipHeight) >= 0) {
        position.vertical = 'bottom';
      } else if (offset.top - (pointerSize + tooltipHeight) > 0) {
        position.vertical = 'top';
      }

      // determine where to position horizontally
      let roomToLeftHalf = false;
      let roomToRightHalf = false;

      // get calculations to see if there is room to the left and right to center it
      if (offset.left + (t.width() / 2) - (tooltipWidth / 2)) {
        roomToLeftHalf = true;
      }

      if (window.innerWidth - (offset.left + (t.width() / 2) + (tooltipWidth / 2)) >= 0) {
        roomToRightHalf = true;
      }

      // validate ability to center the tooltip horizontally
      if (roomToLeftHalf && roomToRightHalf) {
        // there is room to center the tooltip horizontally so leave it as is
      } else {
        // since we can't center it lets do the following...
        // start by trying to put it to the right
        if (window.innerWidth - (offset.left + tooltipWidth) >= 0) {
          position.horizontal = 'left'; // this aligns it to the left edge of the trigger extending to the right
        } else
        // try putting it to the left
        if (offset.left >= tooltipWidth) {
          position.horizontal = 'right'; // this aligns it to the right edge of the trigger extending to the left
        }
      }

      // apply final positioning
      vm.position = position;
    },
    positionTooltip: function (t) {
      const vm = this;

      const tooltip = $('#tooltip');
      const tooltipHeight = tooltip.outerHeight();
      const tooltipWidth = tooltip.outerWidth();

      const pointerLength = 8;

      if (vm.position) {
        // get the location of the target elements top, right, bottom, left
        const offset = t.offset();
        const outerWidth = t.outerWidth();
        const top = offset.top;
        const left = offset.left;
        const bottom = offset.top + t.outerHeight();
        const right = offset.left + t.outerWidth();

        if (vm.position) {
          // calculate top position  
          if (vm.position.vertical === 'top') {
            // position above
            vm.top = top - tooltipHeight - pointerLength;
            vm.pointerTop = top - pointerLength - 1;
            vm.pointerDirection = 'down';
          } else if (vm.position.vertical === 'bottom') {
            // position below
            vm.top = bottom + pointerLength;
            vm.pointerTop = bottom + 1;
            vm.pointerDirection = 'up';
          } else {
            // vertically center
            let halfHeight = (bottom - top) / 2;

            vm.top = top + halfHeight;
          }

          // calculate left position
          if (vm.position.vertical === 'top' || vm.position.vertical === 'bottom') {
            // position justified to the left/right edge of the target
            const tCenter = left + (outerWidth / 2);

            if (vm.position.horizontal === 'left') {
              vm.left = left;
            } else if (vm.position.horizontal === 'right') {
              vm.left = left + outerWidth - tooltipWidth;
            } else {
              vm.left = tCenter - (tooltipWidth / 2);
            }

            vm.pointerLeft = tCenter;
          } else {
            // position completely off to the left or right of the target
            if (vm.position.horizontal === 'left') {
              vm.left = left - tooltipWidth - pointerLength;
            } else if (vm.position.horizontal === 'right') {
              vm.left = right + tooltipWidth + pointerLength;
            }
          }
        }
      }
    },
    resetTooltip() {
      const vm = this;
      // reset the entire tooltip
      vm.text = '';
      vm.subtext = '';
      vm.visible = false;
      vm.position = null;
      vm.top = 0;
      vm.left = 0;
      vm.pointerTop = 0;
      vm.pointerLeft = 0;
      vm.pointerLocation = null;
      vm.pointerDirection = '';
      vm.forcedWidth = ''; 
    }
  },
  mounted: function() {
    const vm = this;

    $(document).ready(function (event) {
      $(document).on('mouseenter', '*[augtip]', function(e) {
        const t = $(this);

        vm.text = t.attr('augtip');

        if (t.attr('augtip-trusted-text')) {
          vm.trustedSubtext = t.attr('augtip-trusted-text');
        } else {
          vm.trustedText = false;
        }

        if (t.attr('augtip-subtext')) {
          vm.subtext = t.attr('augtip-subtext');
        }

        if (t.attr('augtip-trusted-subtext')) {
          vm.trustedSubtext = t.attr('augtip-trusted-subtext');
        } else {
          vm.trustedSubtext = false;
        }

        if (t.attr('augtip-width')) {
          vm.forcedWidth = 'width: ' + t.attr('augtip-width') + 'px;';
        }

        if (t.attr('augtip-position')) {
          // future code - support forced positioning
        }

        vm.$nextTick(function() {
          // make sure we actually have a tooltip to show
          // necessary as the triggering event may have removed the triggering element from the DOM
          if (vm.text) {
            vm.calculateSafeLocation(t);
            vm.positionTooltip(t);

            // If the element stays in the DOM show the tooltip otherwise hide it
            if ($.contains(document, t[0])) {
              setTimeout(function() {
                vm.visible = true;
              }, 250);
            } else {
              vm.visible = false;
            }
          }
        });
      });

      $(document).on('click', '*[augtip]', function(e) {
        vm.top = -10000;
        vm.left = -10000;
        vm.visible = false;

        // delay for a moment to see if the tooltip hover element gets removed before we reposition
        setTimeout(function(){
          const t = $(this);

          vm.text = t.attr('augtip');

          if (t.attr('augtip-subtext')) {
            vm.subtext = t.attr('augtip-subtext');
          }

          if (t.attr('augtip-width')) {
            vm.forcedWidth = 'width: ' + t.attr('augtip-width') + 'px;';
          }

          if (t.attr('augtip-position')) {
            // future code - support forced positioning
          }

          vm.$nextTick(function() {
            // make sure we actually have a tooltip to show
            // necessary as the triggering event may have removed the triggering element from the DOM
            if (vm.text) {
              vm.calculateSafeLocation(t);
              vm.positionTooltip(t);

              // If the element stays in the DOM show the tooltip otherwise hide it
              if ($.contains(document, t[0])) {
                setTimeout(function() {
                  vm.visible = true;
                }, 250);
              } else {
                vm.visible = false;
              }
            }
          });

        }, 2000);
      });

      $(document).on('mouseleave', '*[augtip]', function(e) {
        vm.resetTooltip();
      });
    });

    if (_.get(vm, '$aug.eventbus', false)) {
      vm.$aug.eventbus.$on('hide-tooltip', function() {
        vm.resetTooltip();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './../../styles/variables';

  @keyframes tooltip-fade {
    0% {
      opacity: 0;

    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  #tooltip {
    position: fixed;
    z-index: 2147483647;

    display: inline-block;

    font-size: 12px;
    font-weight: bold;
    line-height: 16px;

    text-align: center;
    white-space: normal;
    text-transform: none;
    pointer-events: none;

    color: $white;
    border: solid 1px $white;
    background-color: $gray-dark;

    &:not(.visible) {
      opacity: 0;
    }

    &.visible {
      animation: tooltip-fade .3s;
    }

    &.visible {
      opacity: 1;
    }

    &.nowrap {
      white-space: nowrap;
    }

    .text,
    .subtext {
      padding: 7px 15px;
    }

    .text {
      color: $white;
      background-color: $gray-dark;
    }

    .subtext {
      font-size: 12px;

      color: $gray-dark;
      background-color: rgba(239, 239, 239, 1);
    }

    .pointer {
      position: fixed;

      display: block;

      width: 0;
      height: 0;

      content: '';
      transform: translateX(-50%);

      border-right: 8px solid transparent;
      border-bottom: 8px solid $white;
      border-left: 8px solid transparent;

      &:before {
        position: absolute;
        top: calc(100% + 2px);
        left: 50%;

        display: block;

        width: 0;
        height: 0;

        content: '';
        transform: translateX(-50%);

        border-right: 6px solid transparent;
        border-bottom: 6px solid $gray-dark;
        border-left: 6px solid transparent;
      }

      &.down {
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }
</style>
